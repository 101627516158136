import DatasourceAPI from "./DatasourceAPI";
import DatasourceAccountsAPI from "./DatasourceAccountsAPI";
import { SessionStorage } from "./BrowserStorage";
import ConsentRequestService from "./ConsentRequestService";
import { searchObjByKeyValue } from "../../utils/CommonFunctions";

let DatasourceService = {};

DatasourceService.getAllDatasources = function() {
	// TOOD: save list in SessionStorage
	return DatasourceAPI.getDatasources();
};

DatasourceService.requestConnection = function (dsid, after, connected_accounts, selected_ds, connectionType, transaction_id, params) {
	//====== Start ======
	// Add DS Id to cr token history to that particular transaction history
	// For case to match this id while returning back if transaction ID is not valid
	let currentTokenHistory = ConsentRequestService.tokenHistory(); 
	
	if (currentTokenHistory && currentTokenHistory !== "null" && currentTokenHistory !== "undefined") {
		Object.keys(currentTokenHistory).forEach(item => {
			if (item === transaction_id) {
				currentTokenHistory[item].connectingDsID = dsid;
			}
		});
	
		SessionStorage.set("cr_token_history", JSON.stringify(currentTokenHistory));
	}
	
	let tempTransaction_id = transaction_id;

	//====== END =======
	
	// Add the CR_token in session for Add COnnection and REquired Rs flow

	if (after) {
		if (after.toString() === "FIND_CONNECTIONS" || after.toString() === "PROFILE_PAGE") {
			tempTransaction_id = after;
			SessionStorage.set("cr_token", tempTransaction_id);
			SessionStorage.set("cr_token_history", JSON.stringify({ [tempTransaction_id]: {"transaction_id": tempTransaction_id, "connectingDsID": dsid }}))
		}
	}
	
	SessionStorage.remove("isAutoPopulatedOnLoad");
	SessionStorage.set(`ds_after_type-${tempTransaction_id}`, after);
	SessionStorage.set("connected_accounts_history", JSON.stringify(connected_accounts || []));
	SessionStorage.set(`selected_ds-${tempTransaction_id}`, JSON.stringify(selected_ds));
	SessionStorage.set(`selected_connection_type-${tempTransaction_id}`, connectionType);
	
	return new Promise((resolve, reject) => {
		DatasourceAPI.requestConnection(dsid, params).then(
			result => {
				SessionStorage.set(`ds_id-${tempTransaction_id}`, dsid);
				SessionStorage.set(
					`ds_request_id-${tempTransaction_id}`,
					result.pending_auth_request_id
				);
				SessionStorage.set(
					`ds_request_secret-${tempTransaction_id}`,
					result.session_key
				);				

				resolve(result);
			},
			error => reject(error)
		);
	});
};

DatasourceService.isAccountExpired = function(account)  {
	let accountRot = account.rot;

	switch (true) {
		case !accountRot:
			account.expired = true;
			break;

		case accountRot.expires_at > new Date(Date.now()).toISOString():

			account.expired = false;
			break;

		case accountRot.expires_at === null:
			account.expired = false;
			break;
			
		default: 
			account.expired = true;
	}

	return account
}


DatasourceService.connectionRequestState = async function(datasourceID) {
	let token = SessionStorage.get("cr_token");
	let currentTokenHistory = ConsentRequestService.tokenHistory(); 
	
	// Check if the Dsid matching with the history to get the transactionID
	const filteredValue = await Promise.resolve(searchObjByKeyValue("connectingDsID", currentTokenHistory, datasourceID));

	// Check the match found
	if (Object.keys(filteredValue).length !== 0) { 
		token = filteredValue.transaction_id;
		SessionStorage.set('cr_token', filteredValue.transaction_id);
	}
	
	let dsid = SessionStorage.get(`ds_id-${token}`);
	let requestId = SessionStorage.get(`ds_request_id-${token}`);
	let requestSecret = SessionStorage.get(`ds_request_secret-${token}`);
	let ds_after_type = SessionStorage.get(`ds_after_type-${token}`);
	let selected_connection_type = SessionStorage.get(`selected_connection_type-${token}`);
	let internal_redirect;
	
	// Redirect based on the previous screen.
	if (ds_after_type && ds_after_type.toString() === "FIND_CONNECTIONS") {
		internal_redirect =  window.GLOBAL_PATH + 'my-account/find-connections';
	} else if (ds_after_type && ds_after_type.toString() === "PROFILE_PAGE") {
		internal_redirect =  window.GLOBAL_PATH + 'profile';
	} else {

		internal_redirect = window.GLOBAL_PATH + `consent/${token}`;
	}
	return new Promise((resolve, reject) => {
		DatasourceAPI.connectionRequestState(dsid, requestId, requestSecret).then(
			result => {
				
				// Clear History if user comes from add connections or required rs
				if (ds_after_type && (ds_after_type.toString() === "FIND_CONNECTIONS" || ds_after_type.toString() === "PROFILE_PAGE")) {
					SessionStorage.remove(`selected_connection_type-${token}`);
					SessionStorage.remove(`cr_token_history`);
				} 

				result.internal_redirect = internal_redirect;
				result.selected_connection_type = selected_connection_type;
				
				SessionStorage.remove(`ds_after_type-${token}`);
				SessionStorage.remove(`ds_id-${token}`);
				SessionStorage.remove(`ds_request_id-${token}`);
				SessionStorage.remove(`ds_request_secret-${token}`);

				resolve(result);

			},
			error => {
				if (error.data) {
					error.data.last_view = internal_redirect;
				}
				reject(error)
			}
		);
	});
};

DatasourceService.getMyDatasourceAccounts = function() {
	return DatasourceAccountsAPI.getMyDatasourceAccounts();
};

DatasourceService.editNickname = function (dsAccountID, nickname) {
	return DatasourceAccountsAPI.editNickname(dsAccountID, nickname);
};

DatasourceService.disableMyDatasourceAccount = function (dsAccountID) {
	return DatasourceAccountsAPI.disableMyDatasourceAccount(dsAccountID);
};

DatasourceService.getOrganizationAccounts = function () {
	return DatasourceAccountsAPI.getOrganizationAccounts();
};

DatasourceService.getOrganizationById = function (orgID) {
	return DatasourceAccountsAPI.getOrganizationById(orgID);
}

DatasourceService.getPermissions = function () {
	return DatasourceAccountsAPI.getPermissions();
}

DatasourceService.disablePermission = function (permissionID) {
	return DatasourceAccountsAPI.disablePermission(permissionID);
}

DatasourceService.disableBulkPermissions = function (permissionsIdArray) {
	return DatasourceAccountsAPI.disableBulkPermissions(permissionsIdArray);
}

DatasourceService.getPermissionById = function (permissionsId) {
	return DatasourceAccountsAPI.getPermissionById(permissionsId);
}

DatasourceService.disableEnrolledClient = function (enrolledClientId) {
	return DatasourceAccountsAPI.disableEnrolledClient(enrolledClientId);
}

DatasourceService.disableDSPermissions = function (dsaIdentifier) {
	return DatasourceAccountsAPI.disableDSPermissions(dsaIdentifier);
}

DatasourceService.getConnectedAccountsHistory = function () {
	return new Promise((resolve, reject) => { 
		const result = JSON.parse(SessionStorage.get("connected_accounts_history"));
		
		resolve(result);
	})
}

DatasourceService.getSelectedDs = function () {
	const token = SessionStorage.get("cr_token");
	
	return JSON.parse(SessionStorage.get(`selected_ds-${token}`));
}

DatasourceService.removeConnectedAccountsHistory = function () {
	const token = SessionStorage.get("cr_token");

	SessionStorage.remove("connected_accounts_history");
	SessionStorage.remove(`selected_ds-${token}`);
	SessionStorage.remove(`selected_connection_type-${token}`);
}

DatasourceService.getDatasourceByIDRelations = function (data_source_id) {
	return new Promise((resolve, reject) => {
		DatasourceAccountsAPI.getDatasourceByIDRelations(data_source_id).then(
			result => {
				resolve(result);
			},
			error => reject(error)
		);
	});
}

// moved handleSources from DataSourceSelectorModal
DatasourceService.handleSources = (datasources, connectedAccounts, res_def) => {
  let availableResources = [];

  for (let datasource of datasources) {
    for (let resource of datasource.resources) {
    
      if (res_def && res_def.resource_definition.res_def_id === resource.resource.res_def_id) {
        availableResources.push(datasource);
        break;
      }

      for (let connectedAccount of connectedAccounts) {
        for (let availableResource of availableResources) {
          if(availableResource.data_source_id === connectedAccount.data_source.data_source_id) {
            availableResource.expired = connectedAccount.expired;
            availableResource.ds_account_id = connectedAccount.ds_account_id;
          }
        } 
      }
    }
  }  

  // Get all the conneted Accounts based on the available resource
  availableResources = availableResources.map(availableResource => {
    let accountsConnected = [];
    
    connectedAccounts.length && connectedAccounts.map(connectedAccount => {
      if (connectedAccount.data_source.data_source_id === availableResource.data_source_id) {
        accountsConnected.push(connectedAccount)
      }
      return connectedAccount;
    })

    // Alphabatical sort for nickname of grouped accounts
    accountsConnected.sort((a, b) => a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase(), undefined, { numeric: true, sensitivity: 'base'}));
    
    // # Sort by Expired
    accountsConnected.sort((a, b) => a.expired - b.expired);

    // # Sort by Disabled 
    accountsConnected.sort((a, b) => Boolean(a.disabled) - Boolean(b.disabled));


    availableResource.connectedAccounts = accountsConnected.length ? accountsConnected: [{}];
    return availableResource;
  })

  availableResources = availableResources.sort((a, b) => {
    if (b.name.toLowerCase() > a.name.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  return availableResources;
}

export default DatasourceService;
