import React from "react";

// Lib
import i18n from "../../../i18n";

const TOUandPP = ({ request, handleAnalyticsTrack }) => {

  const renderTOUandPP = () => {
    
    return  (
      <div className="consent-docs-link">
        {i18n.t("consent__doc_links", { clientName: request.client.name })}
        {
          request.client.tos_uri
          &&
          <a className="textPrimaryColor" href={request.client.tos_uri} target="_blank" rel="noopener noreferrer" onClick={() => {handleAnalyticsTrack("onClick_event", i18n.t("consent__terms_of_use"), "")}} >{i18n.t("consent__terms_of_use")}</a>
        }

        {
          (request.client.tos_uri && request.client.policy_uri)
          &&
          i18n.t("consent_doc_and")
        }

        {
          request.client.policy_uri
          &&
          <a className="textPrimaryColor" href={request.client.policy_uri} target="_blank" rel="noopener noreferrer" onClick={() => {handleAnalyticsTrack("onClick_event", i18n.t("consent__privacy_policy"), "")}} >{i18n.t("consent__privacy_policy")}</a>
        }
      </div>
    )
  }

  return (
    <>
      {
        (request.client && (request.client.tos_uri || request.client.policy_uri))
        &&
        renderTOUandPP()
      }
    </>
  );
}

export default TOUandPP;