import React, { Component } from "react";

// Library
import AnalyticsService from "analytics-web";
import { withRouter } from 'react-router-dom';

// Components
import NotificationItem from "../presentation/NotificationItem";
import ErrorModal from "../../ErrorModal";
import ConnectionRequiredModal from "../presentation/ConnectionRequiredModal";
import EditNicknameModal2 from "../../EditNicknameModal2";
import NewAccountConnectionModal from "../../NewAccountConnectionModal";

// Services
import ProfileService from "../../Services/ProfileService";
import DatasourceService from "../../Services/DatasourceService";
import { AuthService } from "../../Services/AuthService";
import i18n from "../../../i18n";

// Utils
import  { delegatedAccountsCheck } from  "../../../utils/DelegatedAccounts";
import PromiseAllsettled from "../../../utils/PromiseAllsettled";

// Images
import PlaceholderLogoImg from "../../../assets/logo_placeholder.png";

class ProfileContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      error: null,
      isSecure: true,
      isLoadingProfile: true,
      profile: {
        email: "",
        name: "",
        idp_name: "",
        logo_url: "",
        sub: "",
        verified_sources: []
      },
      lookup_code: this.props.lookup_code,
      hasError: false,
      errorTitle: "",
      errorMessage: "",
      errorButtonText: i18n.t("ok"),
      profile_configs: window.config.profile_configs,

      isOpenConnectionRequiredModal: false,
      isExpiredConnectionRequired: false,
      connectionRequiredModalTitle: "",
      connectionRequiredModalBody: "",
      connectionRequiredModalButton1: "",
      connectionRequiredModalButton2: "",
      requiredDatasource: this.props.dataSource || {},      
      isSendingReq: false,
      connectedAccounts: [],
      datasourcesList: [],
      errorModalButtonAction: this.handleToggleErrorModal,

      changeNickname: false,
      editNicknameTitle: "",
      editNicknameDesc: "",
      changeNicknameValues: {},
      expiredAccountWithNewCredModalOpen: false,

      walletAccountName: window.config.wallet_account_name,
      my_activity_configs: window.config.my_activity_configs
    };
  }

  componentDidMount = async () => {
    const { isInternetDisconnected, dataSource } = this.props;
    let { 
      connectedAccounts,
      profile,
    } = this.state;

    // ProfileService.getNotifications().then(notificationItems => {
    //   this.setState({
    //     notifications: notificationItems,
    //   })
    // })

    //Analytics
    this.handleAnalyticsTrack("onLoad_event");

     // 0. Get User Profile Api
    ProfileService.getMyProfile().then(myProfile => {
      profile = myProfile;
    });

    // ==================================
    // Api calls For Required Connections
    // ==================================

    // Api calls if internet is active and without any errors
    if (!isInternetDisconnected) { 
      
      let promises = [];
      
      // 1. Get all Data sources
  
      promises.push(new Promise((resolve, reject) => {
        DatasourceService.getAllDatasources().then(datasources => {
          resolve(datasources);
        }).catch(e => {
          reject(e)
        })
      }));
      
      // 2. Get user Data sources
  
      promises.push(new Promise((resolve, reject) => {
        DatasourceService.getMyDatasourceAccounts().then(accounts => {
          // 2.1 Sort based on disabled enabled
          const sortedDsAccounts = accounts.sort((a, b) => Boolean(a.disabled) - Boolean(b.disabled));
  
          // 2.1 Check expiry
          sortedDsAccounts.map(account => DatasourceService.isAccountExpired(account));
  
          resolve(sortedDsAccounts);
        }).catch(e => {
          reject(e)
        })
      }));

      // Get connected accounts history

      promises.push(new Promise((resolve, reject) => {
        DatasourceService.getConnectedAccountsHistory().then(result => {
          resolve(result);
        })
      }))

      // 4. Call to get the relations

      if (dataSource && !dataSource.error) { 
        promises.push(
          new Promise((resolve, reject) => {
            DatasourceService.getDatasourceByIDRelations(dataSource.data_source.data_source_id).then((result) => {
              resolve(result);
            });
          })
        );
      }

      return Promise.all(promises).then((results) => { 
        const datasourcesList = results[0];
        connectedAccounts =  results[1];
        const connectedAccountsHistory = results[2];
        const getRelationsByID = results[3];
        
        let res = {
          connectedAccounts,
          datasourcesList,
          getRelationsByID
        };

        // Checks for Success Logins or Errors

        res = this.handleChecksForErrorsAndSuccessLogins(connectedAccountsHistory, res);
        
        this.setState({
          hasError: res.hasError,
          errorTitle: res.errorTitle,
          errorMessage: res.errorMessage,
          errorModalButtonAction: res.errorModalButtonAction,
          profile,
          isLoadingProfile: false,
          isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
          isExpiredConnectionRequired: res.isExpiredConnectionRequired,
          connectionRequiredModalTitle: res.connectionRequiredModalTitle,
          connectionRequiredModalBody: res.connectionRequiredModalBody,
          connectionRequiredModalButton1: res.connectionRequiredModalButton1,
          connectionRequiredModalButton2: res.connectionRequiredModalButton2,
          requiredDatasource: res.requiredDatasource,

          changeNickname: res.changeNickname,
          editNicknameTitle: res.editNicknameTitle,
          editNicknameDesc: res.editNicknameDesc,
          changeNicknameValues: res.changeNicknameValues,
          expiredAccountWithNewCredModalOpen: res.expiredAccountWithNewCredModalOpen,

          connectedAccounts,
          datasourcesList
        })
      }).catch((e) => {
      
        if (e.status && e.status !== 401) {
          this.setState({
            isLoading: false,
            hasError: true,
            errorTitle: i18n.t("profile__connection_required_error_title"),
            errorMessage: i18n.t("profile__connection_required_error_body"),
            errorButtonText: i18n.t("profile__connection_required_error_button"),
            errorModalButtonAction: null,
            isOpenConnectionRequiredModal: false,
          })
        }
      })
    }
  }

  // ========================================
  // Required Connection Modal Actions  Start 
  // ========================================

  handleChecksForErrorsAndSuccessLogins = (connectedAccountsHistory, DataObj) => {
    let { 
      lookup_code, 
      walletAccountName,
      hasError,
      errorTitle,
      errorMessage,
      errorModalButtonAction,
      requiredDatasource
    } = this.state;

    const {
      dataSource,
      isLoginFailedError
    } = this.props;
    
    let selectedDS = DatasourceService.getSelectedDs();

    // 1. If trying to use a DSA which is laready claimed in another account
    
    if (isLoginFailedError && isLoginFailedError.status === 403) {
      DataObj.hasError = true;
      DataObj.errorTitle = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_title");
      DataObj.errorMessage = i18n.t("connect_rs__failure_alreadyused_in_other_wallet_403_desc", {
        rsName: selectedDS.name || selectedDS.data_source.name,
        walletAccountName });
    } else if (lookup_code && lookup_code === "40530") {

      // 2. Request error -  when the flow is already being started on the mobile app or in another web browser

      DataObj.hasError = true;
      DataObj.errorTitle = i18n.t("request_error_title");
      DataObj.errorMessage = i18n.t("request_error_desc");
    } else {

      // 3. Checks after succesful login/No errors
      // 3.1 Checks for required DS

      DataObj = this.handleRequiredConnectionCheck(DataObj.datasourcesList, DataObj.connectedAccounts, requiredDatasource, DataObj.getRelationsByID);
      
      // 3.2 Checks for login credentials

      if (dataSource) {

        // Analytics
        const analyticText = dataSource.selected_connection_type === "RENEW_CONNECTION" ? "Expired required data source modal - Renew" : "Required data source modal - Log in";
        this.handleAnalyticsTrack("onClick_event", analyticText);
        
        DataObj = this.handleCheckLoginCredentials(connectedAccountsHistory, selectedDS, DataObj, hasError, errorTitle, errorMessage, errorModalButtonAction);
      }
    }

    return DataObj;
  }

  handleRequiredConnectionCheck = (datasources, connectedAccounts, requiredDatasource, getRelationsByID) => {
    let { 
      isOpenConnectionRequiredModal, 
      connectionRequiredModalTitle,
      connectionRequiredModalBody,
      connectionRequiredModalButton1,
      connectionRequiredModalButton2, 
      isExpiredConnectionRequired,
    } = this.state;

    let result = {
      isOpenConnectionRequiredModal, 
      connectionRequiredModalTitle,
      connectionRequiredModalBody,
      connectionRequiredModalButton1,
      connectionRequiredModalButton2, 
      isExpiredConnectionRequired,
      requiredDatasource,
      connectedAccounts,
      abortLoops: false,
      getRelationsByID
    };

    for (let i = 0; i < datasources.length && !result.abortLoops; i++) {
      for (let j = 0; j < connectedAccounts.length && !result.abortLoops; j++) {
        
        // 1. Check for the required DS
        if (datasources[i].required === true) {

          const foundConnectedAccount = connectedAccounts.filter(connectedAcc => datasources[i].data_source_id === connectedAcc.data_source.data_source_id);

          // 1.1 if Required Ds is connected but expired
          this.handleCheckCaseForConnectedRequiredDSExpiry(foundConnectedAccount, requiredDatasource, result);
          
          // 1.2 if Required DS is not connected
          // Check if the Datsource is there in connected account arr

          this.handleCheckCaseForNotConnectedRequiredDS(foundConnectedAccount, datasources[i], requiredDatasource, result);
          
        }
      }
    }

    return result;
  }

  handleCheckCaseForConnectedRequiredDSExpiry = (foundConnectedAccount, requiredDatasource, DataObj) => {
    if (foundConnectedAccount.length && !foundConnectedAccount.find(foundAcc => foundAcc.expired === false)) {
      
      // Modal Content
      DataObj.isOpenConnectionRequiredModal = true;
      DataObj.isExpiredConnectionRequired = true;
      DataObj.connectionRequiredModalTitle = i18n.t("profile__expired_connection_required_modal_title", { dsName: foundConnectedAccount[0].data_source.name });
      DataObj.connectionRequiredModalBody = i18n.t("profile__expired_connection_required_modal_body", { dsName: foundConnectedAccount[0].data_source.name });
      DataObj.connectionRequiredModalButton1 = i18n.t("profile__expired_connection_required_modal_button1");
      DataObj.connectionRequiredModalButton2 =  i18n.t("profile__expired_connection_required_modal_button2");
      DataObj.requiredDatasource = ((Object.keys(requiredDatasource).length === 0) || requiredDatasource.error) ? foundConnectedAccount[0] : requiredDatasource;
      DataObj.abortLoops = true;
    } 

    return DataObj;
  }

  handleCheckCaseForNotConnectedRequiredDS = (foundConnectedAccount, currentDataSource, requiredDatasource, DataObj) => {
    
    if (!foundConnectedAccount.length) {

      // Modal Content
      DataObj.isOpenConnectionRequiredModal = true;
      DataObj.connectionRequiredModalTitle = i18n.t("profile__new_connection_required_modal_title", { dsName: currentDataSource.name });
      DataObj.connectionRequiredModalBody = i18n.t("profile__new_connection_required_modal_body", { dsName: currentDataSource.name });
      DataObj.connectionRequiredModalButton2 = i18n.t("profile__new_connection_required_modal_button");
      DataObj.requiredDatasource.data_source = ((Object.keys(requiredDatasource).length === 0) || requiredDatasource.error) ? currentDataSource : requiredDatasource.data_source;
      DataObj.abortLoops = true;
    }
  }


  handleCheckLoginCredentials = (connectedAccountsHistory, selectedDS, requiredConnectionState, hasError, errorTitle, errorMessage, errorModalButtonAction) => {
    let { 
      requiredDatasource,
      changeNickname,
      expiredAccountWithNewCredModalOpen,
      changeNicknameValues
    } = this.state;

    let result = {
      changeNickname,
      editNicknameTitle: i18n.t("find_connection_edit_nickname_modal__title", { dsName: requiredConnectionState?.requiredDatasource?.data_source?.name }),
      editNicknameDesc: i18n.t("edit_nickname_modal__new_connection_desc"),
      changeNicknameValues,
      expiredAccountWithNewCredModalOpen,
      hasError, 
      errorTitle, 
      errorMessage,
      errorModalButtonAction,
      isOpenConnectionRequiredModal: requiredConnectionState.isOpenConnectionRequiredModal,
      isExpiredConnectionRequired: requiredConnectionState.isExpiredConnectionRequired,
      connectionRequiredModalTitle: requiredConnectionState.connectionRequiredModalTitle,
      connectionRequiredModalBody: requiredConnectionState.connectionRequiredModalBody,
      connectionRequiredModalButton1: requiredConnectionState.connectionRequiredModalButton1,
      connectionRequiredModalButton2: requiredConnectionState.connectionRequiredModalButton2,
      requiredDatasource: requiredConnectionState.requiredDatasource,
    };
    
    if (requiredDatasource && requiredDatasource.ds_account_id) {
     
      // 0 Check for delegated accounts
      
      const delegatedAccounts = delegatedAccountsCheck(requiredConnectionState.connectedAccounts, requiredDatasource, requiredConnectionState.getRelationsByID);
      
      result.requiredDatasource.delegatedAccounts = delegatedAccounts;

      // 0.1 Add default nickname for the connected account

      result.changeNicknameValues = { 
        [requiredDatasource.ds_account_id]: { nickname: requiredDatasource.nickname } 
      };

      // 0.2 Add default nickname for delegated accounts

      if (delegatedAccounts.length) {
        delegatedAccounts.forEach(delegatedAccount => {
          result.changeNicknameValues = { 
            ...result.changeNicknameValues,
            [delegatedAccount.ds_account_id]: { nickname: delegatedAccount.nickname }
          };
        })
      }

      // 1. Open Edit Nickname modal for a new connection

      result = this.handleOpenEditNicknameModalForNewConnection(requiredDatasource, result)
  
      if (connectedAccountsHistory && connectedAccountsHistory.length) {
      
        const foundConnectedAccount = connectedAccountsHistory.filter(account => (account.ds_account_id === requiredDatasource.ds_account_id));

        // 2. IF Same Credentials to renew
        if (requiredDatasource.ds_account_id === selectedDS.ds_account_id) {
          if (requiredDatasource.selected_connection_type === "RENEW_CONNECTION") {
            result.changeNickname = true;
            result.editNicknameTitle = i18n.t("edit_nickname_modal__renew_connection_title");
            result.editNicknameDesc = i18n.t("edit_nickname_modal__renew_connection_desc");
            result.isOpenConnectionRequiredModal = false;
            result.isExpiredConnectionRequired = false;
          } 
        } else {

          // 3. IF New Credentials to connect/renew Check Cases

          result = this.handleNewCredentialsToConnectRenewChecks(foundConnectedAccount, result);

        }
          
        // 4. IF New Credentials to Renew a connection

        result = this.handleNewCredentialsToRenewConnection(foundConnectedAccount, requiredDatasource, result);
      }    
    }

    return result;
  }

  // ==============================================
  // handleCheckLoginCredentials Secondary Methods
  // ==============================================

  handleOpenEditNicknameModalForNewConnection = (requiredDatasource, DataObj) => {

    if (requiredDatasource.selected_connection_type !== "RENEW_CONNECTION" && !requiredDatasource.expired) {

      DataObj.changeNickname = true;
      DataObj.isOpenConnectionRequiredModal = false;
      DataObj.isExpiredConnectionRequired = false;
    }

    return DataObj;
  }

  handleNewCredentialsToRenewConnection = (foundConnectedAccount, requiredDatasource, DataObj) => {

    if (!foundConnectedAccount.length && requiredDatasource.selected_connection_type === "RENEW_CONNECTION") {
      DataObj.expiredAccountWithNewCredModalOpen = true;
      DataObj.changeNickname = false;
      DataObj.isOpenConnectionRequiredModal = false;
      DataObj.isExpiredConnectionRequired = false;
    }

    return DataObj;
  }

  handleNewCredentialsToConnectRenewChecks = (foundConnectedAccount, DataObj) => {

    // 3.1 IF New Credentials to connect/renew but those cred already used in another which is connected
    
    if (foundConnectedAccount.length) {
      if (!foundConnectedAccount[0].expired && !foundConnectedAccount[0].disabled) {
        DataObj.hasError = true;
        DataObj.errorTitle = i18n.t("connect_rs__failure_already_connected_title");
        DataObj.errorMessage = i18n.t("connect_rs__failure_already_connected_desc");
        DataObj.errorModalButtonAction = this.handleToggleErrorModal;
        DataObj.changeNickname = false;
        DataObj.isOpenConnectionRequiredModal = false;
        DataObj.isExpiredConnectionRequired = false;
      } else {

        // 3.2. IF New Credentials to connect/renew but those cred already used in another which is not connected

        DataObj.expiredAccountWithNewCredModalOpen = true;
        DataObj.changeNickname = false;
        DataObj.isOpenConnectionRequiredModal = false;
        DataObj.isExpiredConnectionRequired = false;
      }
    } 

    return DataObj;
  }

  // =================================================
  // handleCheckLoginCredentials Secondary Methods END
  // ==================================================


  handlelogoutAction = async (e) => {
    e.preventDefault();

    await AuthService.logout();

    this.handleAnalyticsTrack("onClick_event", `Expired required data source modal - Do it later`);
  }

  handleConnectRsAction = async (connectionType) => {
    const { requiredDatasource, connectedAccounts } = this.state;
    
    try {
      // Spinner
      this.setState({
        isSendingReq: true
      });

      // Api Call

      const result = await DatasourceService.requestConnection(requiredDatasource.data_source.data_source_id, "PROFILE_PAGE", connectedAccounts, requiredDatasource, connectionType);
      
      document.location = result.auth_request;
    } catch (e) {
      if (e.status && e.status !== 401) {
        this.setState({ 
          isSendingReq: false,
          hasError: true,
          errorTitle: i18n.t("findConnections_connection_failure_title"),
          errorMessage: i18n.t("findConnections_connection_failure_desc"),
        })
      }
    }
  } 

  // Onchange for nickname

  handleOnNicknameChange = (e) => {
    const { changeNicknameValues } = this.state;
    const { name, value } = e.target; 

    this.setState({ changeNicknameValues: { ...changeNicknameValues, [name]: { "nickname": value } } })
  }

  handleEditNickNameAction = async (nicknameValues) => {
    let { 
      requiredDatasource, 
      datasourcesList, 
      connectedAccounts, 
    } = this.state;

    let DataObj = {
      connectedAccounts,
      hasError: false,
      changeNickname: false,
      errorTitle: "",
      errorMessage: "",
      nicknameValues,
    }
    
    let res = {
      requiredDatasource
    }

    // Remove Browser History
    this.handleRemoveHistory();
    
    // Clearing browser state 
    window.history.replaceState("state", null);

    // Spinner
    this.setState({ isSendingReq: true });

    let promisesArr = [];
    
    for (let connectedAccount of connectedAccounts) { 
      for (const key in nicknameValues) {
        if ((connectedAccount.ds_account_id === key) && (connectedAccount.nickname !== nicknameValues[key].nickname)) {
          promisesArr.push(
            new Promise((resolve, reject) => {
              DatasourceService.editNickname(key, nicknameValues[key].nickname).then((result) => {
                resolve(result);
              })
              .catch((e) => {
                e.ds_account_id = key;
                reject(e);
              });
            })
          );
        }
      }
    }

    // Get the result for all the nickname methods
    if (promisesArr.length) {

      await PromiseAllsettled(promisesArr).then(allResults=> {
        
        // 1. Update the Nickname

        DataObj = this.handleUpdateNicknameForList(DataObj, allResults);
  
        // 2. Filter If we have a error except 409
        const except409ErrorFilter = allResults.filter(item => ((item.status === "rejected") && (item.reason.status !== 409)));
        
        if (except409ErrorFilter.length) {
          DataObj.changeNickname = false;
          DataObj.hasError = true;
          DataObj.errorTitle = i18n.t("edit_nickname_modal__failure_nickname_update_title");
          DataObj.errorMessage = i18n.t("edit_nickname_modal__failure_nickname_update_desc");
        }
      });
    } else {
      DataObj.requiredDatasource = {};
    }

    // Checks for required DS
    if (!DataObj.changeNickname) {
      res = this.handleRequiredConnectionCheck(datasourcesList, DataObj.connectedAccounts, requiredDatasource);
    }

    this.setState({
      connectedAccounts: DataObj.connectedAccounts,
      changeNickname: DataObj.changeNickname,
      changeNicknameValues: DataObj.nicknameValues,
      isSendingReq: false,
      hasError: DataObj.hasError,
      errorTitle: DataObj.errorMessage,
      errorMessage: DataObj.errorMessage,

      isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
      isExpiredConnectionRequired: res.isExpiredConnectionRequired,
      connectionRequiredModalTitle: res.connectionRequiredModalTitle,
      connectionRequiredModalBody: res.connectionRequiredModalBody,
      connectionRequiredModalButton1: res.connectionRequiredModalButton1,
      connectionRequiredModalButton2: res.connectionRequiredModalButton2,
      requiredDatasource: res.requiredDatasource,
    })
  }

  //===========================================
  // Secondary functions for editnickname Start
  //===========================================

  handleUpdateNicknameForList = (DataObj, allResults) => {
    DataObj.connectedAccounts.map((connectedAccount) => {
      for (let resultItem of allResults) { 

        // For Fulfiled succesful calls
        if (resultItem.status === "fulfilled" && connectedAccount.ds_account_id === resultItem.value.ds_account_id) {

          //Analytics
          this.handleAnalyticsTrack("onClick_event", `Submit edit nickname with ${resultItem.value.nickname} for ${resultItem.value.ds_account_id}`);

          connectedAccount.nickname = resultItem.value.nickname;
        }

        // For Failed 409 error calls

        if (resultItem.status === "rejected") {
          DataObj.hasError = false;
          DataObj.changeNickname = true;

          if (resultItem.reason.status === 409) {
            DataObj.nicknameValues[resultItem.reason.ds_account_id].error = i18n.t("edit_nickname_modal__failure_nickname_409_title");
          } 
        }
      }
      
      return connectedAccount;
    });

    return DataObj;
  }

  //===========================================
  // Secondary functions for editnickname End
  //===========================================

  handleRemoveHistory = () => {
    DatasourceService.removeConnectedAccountsHistory();
  }

  handleDisconnectExpiredAccountWithNewCredModal = async () => {
    let { requiredDatasource, datasourcesList, connectedAccounts } = this.state;

    // Remove Browser History
    this.handleRemoveHistory();

    try {
      const result = await DatasourceService.disableMyDatasourceAccount(requiredDatasource.ds_account_id);

      // Clearing browser state 
      window.history.replaceState("state", null);
 
      // Update the local state
      connectedAccounts = connectedAccounts.filter(connectedAccount => connectedAccount.ds_account_id !== result.ds_account_id);
   
      // Checks for required DS
      const res = this.handleRequiredConnectionCheck(datasourcesList, connectedAccounts, requiredDatasource);

      this.setState({
        expiredAccountWithNewCredModalOpen: false,
        isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
        isExpiredConnectionRequired: res.isExpiredConnectionRequired,
        connectionRequiredModalTitle: res.connectionRequiredModalTitle,
        connectionRequiredModalBody: res.connectionRequiredModalBody,
        connectionRequiredModalButton1: res.connectionRequiredModalButton1,
        connectionRequiredModalButton2: res.connectionRequiredModalButton2,
        requiredDatasource: res.requiredDatasource,
        connectedAccounts
      });
    } catch (error) {

      if (error.status !== 401) {
        this.setState({
          hasError: true,
          errorTitle: i18n.t("connectionDetail__failure_disconnect_title"),
          errorMessage: i18n.t("connectionDetail__failure_disconnect_desc"),
          isSendingReq: false,
          expiredAccountWithNewCredModalOpen: false,
        })
      }
    }  
  }

  handleCloseExpiredAccountWithNewCredModal = () => {
    this.setState({
      changeNickname: true,
      expiredAccountWithNewCredModalOpen: false,
    })
  }


  // ========================================
  // END 
  // ========================================

  renderCardProfileName = () => {

    const { isLoadingProfile, profile, profile_configs } = this.state;

    if (profile_configs && profile_configs.name) {
      return (
        <div className="profile-user-name">
          { 
            !profile.name && !isLoadingProfile
            ?
            <span>-</span>
            : 
            profile.name
          }
        </div>
      )
    }
  }


  renderNotifications = (notifications, numberOfNotificationToShow) => {
    let renderNotification = notifications;

    // Only render first two

    if (notifications.length > numberOfNotificationToShow) {
      renderNotification = notifications.slice(0, numberOfNotificationToShow);
    }

    return renderNotification.map(notification => (
      <NotificationItem 
        key={notification.id}
        notification={notification}
        handleNotificationClick={this.handleNotificationClick}
      />
    ))
  }

  handleNotificationClick = (notificationItem) => {
    console.log("notificationItem:", notificationItem)
  }

  handleBubbleClick = () => {
    console.log("HIt Bubble")
  }

  handleToggleErrorModal = () => {
    const { datasourcesList, connectedAccounts } = this.state;

    // Clearing browser state 
    window.history.replaceState("state", null);

    // Checks for required DS
    const res = this.handleRequiredConnectionCheck(datasourcesList, connectedAccounts, {});

    this.setState({ 
      lookup_code: "", 
      hasError: false,

      isOpenConnectionRequiredModal: res.isOpenConnectionRequiredModal,
      isExpiredConnectionRequired: res.isExpiredConnectionRequired,
      connectionRequiredModalTitle: res.connectionRequiredModalTitle,
      connectionRequiredModalBody: res.connectionRequiredModalBody,
      connectionRequiredModalButton1: res.connectionRequiredModalButton1,
      connectionRequiredModalButton2: res.connectionRequiredModalButton2,
      requiredDatasource: res.requiredDatasource,
    })
  }

  // Tracking

  handleAnalyticsTrack = (type, action) => {
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: "Profile Page",
      actions: action,
      url: window.location.hostname + window.location.pathname
    })
  }

  // Add Connection Redirect

  handleFindConnections = () => {
    // Analytics
    this.handleAnalyticsTrack("onClick_event", `Add Connection button`);

    this.props.history.push({ 
      pathname: window.GLOBAL_PATH + "my-account/find-connections",
      state: {
        previousPage: "PROFILE_PAGE"
      }
    })
  }

  render() {
    const {
      profile,
      // isSecure,
      notifications,
      hasError,
      errorTitle,
      errorMessage,
      errorButtonText,
      errorModalButtonAction,
      profile_configs,
      isLoadingProfile,
      isSendingReq,

      isOpenConnectionRequiredModal,
      isExpiredConnectionRequired,
      connectionRequiredModalTitle,
      connectionRequiredModalBody,
      connectionRequiredModalButton1,
      connectionRequiredModalButton2,
      requiredDatasource,

      changeNickname,
      editNicknameTitle,
      editNicknameDesc,
      changeNicknameValues,
      expiredAccountWithNewCredModalOpen,

      my_activity_configs,
    } = this.state;
    
    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;

    const logoImg = profile.logo_url ? profile.logo_url : PlaceholderLogoImg;
   
    return (
      <div className="profile-user">
        <h1>{i18n.t("sidebar__my_account")}</h1>

        {/* Add COnenction Button */}

        {
          my_activity_configs.multiple_accounts_enable
          ?
          <button className="addButton backgroundPrimaryColor" onClick={this.handleFindConnections}>{i18n.t("connections__add_button")}</button>
          :
          null
        }

        {/* Line Break */}

        <div className="breakLine" />

        {/* Profile User Box */}

        <div
          className="profile-user-wrapper"
          style={{ background: (profile_configs && profile_configs.gradient_color_1 && profile_configs.gradient_color_2)
            ?
            `linear-gradient(to bottom, ${profile_configs.gradient_color_1}, ${profile_configs.gradient_color_2} 92%)`
            :
            `var(--primary-color)`}} 
        >
          <div className="logo-section">
            {
              isLoadingProfile
              ?
              <div className="placeholder-div" />
              :
              <img
                src={logoImg}
                alt={profile.idp_name + " logo"}
                className="identity-verifier-logo"
                height="42"
                width="42" 
              />
            }
          </div>

          {
            this.renderCardProfileName()
          }
          
          <div className="profile-user-verified">
            {
              profile.verified_sources.length
              ?
              <div className="profile-user-verified-by" key={profile.verified_sources[0].name}>
                <p className="profile-user-titles">
                  {i18n.t("profile__verified_by")}
                </p>
                <div className="profile-user-dynamic-info">
                  <img
                    src={profile.verified_sources[0].logo_url}
                    alt={profile.verified_sources[0].name + " logo"}
                    className="identity-verifier-logo"
                  />
                </div> 
              </div>
              :
              null
            }

            {/* <div className="profile-user-add-verifier-wrapper">
              <AddCircleOutlineRoundedIcon className="verifier-symbol textPrimaryColor" />
              <a href="/profile" className="brand-link textPrimaryColor borderPrimaryColor">{i18n.t('profile__add_verifier')}</a>
            </div> */}
          </div>
        </div>

        {/* Profile Description */}

        {
          (profile_configs && profile_configs.description)
          &&
          <div className="profile-desc" dangerouslySetInnerHTML={{ __html: i18n.t('profile__description') }} />
        }

        {/* Security Bubble */}

        {/* <Bubble handleBubbleClick={this.handleBubbleClick} isSecure={isSecure} /> */}

        {/* Notifications */}
        {
          (profile_configs && profile_configs.notifications)
          &&
          <div className="notifications-wrapper">
            {
              notifications.length 
              ?
              <React.Fragment>
                <h2>{i18n.t("profile__notifications")}</h2>
                {
                  this.renderNotifications(notifications, profile_configs.numberOfNotificationToShow)
                }
              </React.Fragment>
              :
              <div className="empty-wrapper">
                <div className="title">{i18n.t("profile__notifications_title")}</div>
                <div className="body">{i18n.t("profile__notifications_body")}</div>
              </div>
            }

            {
              notifications.length > profile_configs.numberOfNotificationToShow
              &&
              <button className="notification-history textPrimaryColor">{i18n.t("profile__notifications_history")}</button>
            }

          </div>
        }
        
        {
          hasError
          &&
          <ErrorModal 
            isOpen={hasError}
            errorTitle={errorTitle}
            errorMessage={errorMessage} 
            errorButtonText={errorButtonText} 
            handleToggleModal={errorModalButtonAction}
          />
        }

        {
          isOpenConnectionRequiredModal 
          &&
          <ConnectionRequiredModal 
            isOpen={isOpenConnectionRequiredModal}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            isSendingReq={isSendingReq}
            modalTitle={connectionRequiredModalTitle}
            modalBody={connectionRequiredModalBody}
            modalButton1={connectionRequiredModalButton1}
            modalButton2={connectionRequiredModalButton2}
            handlelogoutAction={this.handlelogoutAction}
            handleConnectRsAction={this.handleConnectRsAction}
            isExpiredConnectionRequired={isExpiredConnectionRequired}
          />
        }

        {
          changeNickname
          &&
          <EditNicknameModal2
            isOpen={changeNickname}
            handleEditNicknameAction={this.handleEditNickNameAction}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            title={editNicknameTitle}
            description={editNicknameDesc}
            nicknameValues={changeNicknameValues}
            handleOnChange={this.handleOnNicknameChange}
            dsName={requiredDatasource.data_source.name}
            logo_url={requiredDatasource.data_source.style.logo_url}
            dsAccount={requiredDatasource}
          />
        }

        {
          expiredAccountWithNewCredModalOpen
          && 
          <NewAccountConnectionModal
            isOpen={expiredAccountWithNewCredModalOpen}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            dataSource={requiredDatasource}
            handleCloseExpiredAccountWithNewCredModal={this.handleCloseExpiredAccountWithNewCredModal}
            handleDisconnectExpiredAccountWithNewCredModal={this.handleDisconnectExpiredAccountWithNewCredModal}
          />
        }
      </div>
    );
  }
}

export default withRouter(ProfileContainer);
